import "./CardComponentStyles.scss";
import React, { FC } from "react";
import CarCardComponentProps from "./CarCardComponentProps";
import CurrencyParse from "../../../utils/CurrencyParse";
import { Link } from "react-router-dom";
import { routes } from "../../routes/RoutesComponent";
import { getUrlCar } from "../../../utils/Contants";
import { Warranty } from "../../assets/warranty";
import { Alert } from "../../assets/alert";
import { WarrantyStar } from "../../assets/warrantyStar";
import { Arrow } from "../../assets/arrow";

const CarCardComponent: FC<CarCardComponentProps> = ({ car, accountUser }) => {
    const [isOrangeFestCar, setIsOrangeFestCar] = React.useState<boolean>(false);

    React.useEffect(() => {
        const carId = parseInt(car?.id);
        if (
            carId === 591 ||
            carId === 561 ||
            carId === 531 ||
            carId === 613 ||
            carId === 582 ||
            carId === 398 ||
            carId === 532 ||
            carId === 517 ||
            carId === 539 ||
            carId === 503
        ) {
            setIsOrangeFestCar(true);
        }
    }, []);

    const warrantyMessage = () => {
        if (!car.warranty_type || car.warranty_type === "null") return "Garantía de 6 meses";

        switch (car.warranty_type) {
            case "Garantía de 6 meses o 4000 km":
                return "Garantía de 6 meses";
            case "Garantía de marca o km":
                return "Garantía de fábrica";
            default:
                return "Garantía de 6 meses";
        }
    };

    console.log("🚀 ~ car:", car);
    return (
        <Link
            to={`${
                accountUser
                    ? routes.detailedCarUser.relativePath + "/" + car.id
                    : routes.detailedCar.relativePath + "/" + getUrlCar(car)
            }`}
            className="w-100 card car_card_component pb-2"
        >
            <div style={{ position: "relative" }}>
                <img
                    src={car.photoUrl}
                    alt={`${car.name} ${car.brand.name} ${car.type.name}`}
                    title={car.name}
                    className="img-fluid img_car"
                    width={300}
                    height={200}
                />
                {/* {isOrangeFestCar && ( */}
                {car?.tag?.name == "Vehículo por ingresar" && (
                    <div
                        style={{
                            width: "100%",
                            height: "26px",
                            backgroundColor: "#000",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "absolute",
                            bottom: 0,
                        }}
                    >
                        <div style={{ color: "white" }}>Estamos preparando el vehículo para ti</div>
                        {/* <img
                    src="/assets/orange-fest.png"
                    style={{
                        width: "100px",
                        height: "16px",
                    }}
                /> */}
                    </div>
                )}
            </div>

            {/* )} */}
            <div className="card-body d-flex flex-column justify-content-evenly">
                {car.tag && (
                    <>
                        {car.tag.name != "Vehículo por ingresar" && car.tag.name != "Black friday %" ? (
                            <>
                                <div
                                    // className="tag-2"
                                    style={{
                                        backgroundColor: car.tag.color,
                                        color:
                                            car.tag.color === "#2E42A1" || car.tag.color == "#4285F4"
                                                ? "#fff"
                                                : car.tag.name === "Fuera de Estandar"
                                                ? "#fff"
                                                : car.tag.color === "#000"
                                                ? "#ec671b"
                                                : "",
                                        position: "absolute",
                                        top: 0,
                                        padding: "0.8em 0.8em",
                                        ...(!car.warranty
                                            ? {
                                                  right: 0,
                                                  borderBottomLeftRadius: "0.5rem",
                                              }
                                            : { left: 0, borderBottomRightRadius: "0.5rem" }),
                                    }}
                                >
                                    {car.tag.name}
                                </div>
                            </>
                        ) : (
                            <div
                                // className="tag-2"
                                style={{
                                    backgroundColor: car.tag.color === "#000" ? "#000" : "#E49B0F",
                                    color: car.tag.color === "#000" ? "#ec671b" : "#fff",

                                    position: "absolute",
                                    top: 0,
                                    ...(!car.warranty && car.tag.name != "Black friday %"
                                        ? {
                                              right: 0,
                                              borderBottomLeftRadius: "0.5rem",
                                          }
                                        : { left: 0, borderBottomRightRadius: "0.5rem" }),
                                    padding: "0.8em 0.8em",
                                }}
                            >
                                {car.tag.name}
                            </div>
                        )}
                    </>
                )}
                {(car.warranty || (car.warranty_type && car.warranty_type != "null")) && (
                    <div>
                        <div
                            className={`btn btn_orange view-btn  d-flex justify-content-center align-items-center ${
                                car.warranty_type == "Garantía de marca o km" ||
                                car.warranty_type == "Garantía de fábrica"
                                    ? "warranty2"
                                    : "warranty"
                            }`}
                        >
                            <Warranty fill={"#000"} /> {warrantyMessage()}
                            {/* Garantía de 6 meses */}
                        </div>
                        {/* <div
                            // className="tag-2"
                            style={{
                                backgroundColor: "#c5f466",
                                color: "#000",
                                borderBottomLeftRadius: "0.5rem",
                                position: "absolute",
                                top: 0,
                                right: 0,
                                padding: "0.8em 0.8em",
                            }}
                        >
                            <Warranty fill="#000" /> Garantía de 6 meses
                        </div> */}
                    </div>
                )}
                <div className="d-flex w-100 align-items-center">
                    <div className="flex-grow overflow-hidden">
                        <h5 className="">{car.name}</h5>
                    </div>
                </div>
                <div className="w-100 text_bold mt-1" style={{ color: "#666C89" }}>
                    {car.type.name}
                </div>

                <div className="w-100 row justify-content-around mt-3" style={{ fontSize: ".9em", margin: "0 auto" }}>
                    <div className="col-3 d-flex align-items-center text_gray p-0">
                        <img
                            src="/assets/icons/calendar.svg"
                            alt="wcar"
                            title="wcar"
                            className="img-fluid img_icon me-2"
                            width={24}
                            height={24}
                            loading="lazy"
                        />
                        <span>{car.year}</span>
                    </div>
                    <div className="col-4 d-flex align-items-center text_gray p-0">
                        <img
                            src="/assets/icons/odometer.svg"
                            alt="wcar"
                            title="wcar"
                            className="img-fluid img_icon me-2"
                            width={24}
                            height={24}
                            loading="lazy"
                        />
                        <span className="text_nowrap">{car.odometer} Km.</span>
                    </div>
                    <div className="col-4 d-flex align-items-center text_gray p-0">
                        <img
                            src="/assets/icons/transmission.svg"
                            alt="wcar"
                            title="wcar"
                            className="img-fluid img_icon me-1"
                            width={24}
                            height={24}
                            loading="lazy"
                        />
                        <span className="text_nowrap">{car.transmission}</span>
                    </div>
                </div>
                <div
                    className={`w-100 d-flex align-items-center justfy-content-between ${
                        car.discount_price ? "mt-2" : "mt-4"
                    }`}
                >
                    <div className="flex-grow-1 text_ellipsis">
                        <h5 className={`text_bold card-price`}>
                            {CurrencyParse.toCop(car.discount_price ?? car.price)}
                        </h5>
                        <span className="text_bold text_gray text_through">
                            {car.discount_price && CurrencyParse.toCop(car.price)}
                        </span>
                    </div>
                    <div className="ms-3 px-4 btn btn_orange view-btn">VER</div>
                </div>
                <div className="d-flex align-items-center text_gray mt-3">
                    <img
                        src="/assets/icons/eye.png"
                        alt="wcar"
                        title="wcar"
                        className="img-fluid img_icon me-2"
                        width={20}
                        height={20}
                        loading="lazy"
                    />
                    <span>{car.quantityPersons} personas vieron este vehículo</span>
                </div>
                {/* <div className="col-12 my-3 position-relative">
                    <span className="warranty_tool_tip position-absolute bg-primary z-10" style={{ display: "block" }}>
                        {car.quantityPersons} personas han {"\n"} visto este vehiculo
                    </span>
                </div> */}
            </div>
        </Link>
    );
};

export default CarCardComponent;
