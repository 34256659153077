import React, { useContext, useState, useEffect } from "react";
import UserEntity, { UserEntityStatus } from "../../../domain/entities/UserEntity";
import ProviderProps from "../../../domain/providers/ProviderProps";
import UserContext from "../../../domain/providers/user/UserContext";
import UserProvider from "../../../domain/providers/user/UserProvider";
import { injectable } from "inversify";
import UserContextType from "../../../domain/providers/user/UserContextType";

// Clave para almacenar el usuario en localStorage
const LOCAL_STORAGE_KEY = "user";

const _Actions: UserContextType = {
    user: undefined,
    setUser: (user: UserEntity | undefined) => {},
    clearUser: () => {},
};

const _Provider = ({ children }: ProviderProps) => {
    const [user, setUser] = useState<UserEntity | undefined>(() => {
        try {
            const storedUser = localStorage.getItem(LOCAL_STORAGE_KEY);
            console.warn("user save", storedUser);
            return storedUser ? JSON.parse(storedUser) : undefined;
        } catch (error) {
            console.error(`Error parsing user from localStorage:`, error);
            return undefined;
        }
    });

    // Persistir en localStorage cada vez que el usuario cambia
    useEffect(() => {
        console.info("Persisting user to localStorage:", user);
        if (user) {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user));
        } else {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
        }
    }, [user]);

    // Actualizar acciones
    _Actions.user = user;
    _Actions.setUser = (newUser: UserEntity | undefined) => {
        if (newUser == undefined) return;
        setUser(newUser);
        console.log("addUser", newUser);
        if (newUser) {
            localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newUser));
        } else {
            localStorage.removeItem(LOCAL_STORAGE_KEY);
        }
    };

    const clearUser = () => {
        console.log("clear user");
        setUser(undefined);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    };

    _Actions.clearUser = clearUser;
    return <UserContext.Provider value={{ user, setUser, clearUser }}>{children}</UserContext.Provider>;
};

@injectable()
class UserProviderImpl implements UserProvider {
    public context = UserContext;

    public Provider = _Provider;

    public Actions = _Actions;
}

export default new UserProviderImpl();
