import "./SignInPageStyles.scss";
import { FC, useContext, useState } from "react";
import Layout from "../../../layout/Layout";
import { useForm } from "react-hook-form";
import Validators from "../../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";
import { Link, useNavigate } from "react-router-dom";
import Icons from "../../../assets/Icons";
import SocialLoginComponent from "../../../components/socialLogin/SocialloginComponent";
import di from "../../../../../di/DependencyInjection";
import SignInUseCase, { SignInUseCaseName } from "../../../../../domain/use_cases/auth/SignInUseCase";
import { Either, isRight, left } from "fp-ts/lib/Either";
import { routes } from "../../../routes/RoutesComponent";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import ModalConfirmEmail from "../../car/detailedCar/component/ModalConfirmEmail";

const SignInPage: FC<{}> = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [openModalConfirmEmail, setOpenModalConfirmEmail] = useState(false);

    const { addToast } = useContext(ModalsContext) as ModalsContextType;

    const _handleSubmit = async (data: any) => {
        const response = await di.get<SignInUseCase>(SignInUseCaseName).call(data.email, data.password);
        if (isRight(response)) {
            navigate(routes.home.relativePath);
        } else {
            addToast(response.left.message ?? "Credenciales erradas", "error", undefined);
        }
    };

    const sendOtp = () => {
        setLoading(true);
        fetch("https://api.wcar.online/api/send-otp-code/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: watch().email,
                login: true,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                setLoading(false);
                console.log("Success:", data);
                if (data?.code) {
                    setOpenModalConfirmEmail(true);
                } else {
                    addToast(data?.error ?? "Ha ocurrido un error", "error", undefined);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                setOpenModalConfirmEmail(false);
                setLoading(false);
                addToast("Error al enviar OTP", "error", undefined);
            });
    };
    return (
        <div className="sign_in_page">
            <Layout>
                {loading && (
                    <div className="loading_container">
                        <LoadingComponent />
                    </div>
                )}
                <div className="container py-4">
                    <div className="row d-flex flex-md-row-reverse">
                        <div className="col-md-6 px-0 px-md-3 mb-4">
                            <img
                                src="/assets/pages/signIn/bg_sign_in.jpeg"
                                className="img-fluid h-100 object_cover"
                                alt="wcar"
                                title="wcar"
                            />
                        </div>
                        <div className="col-md-1"></div>
                        <div className="col-md-4 content_page">
                            <h2 className="text_bold mb-3 mt-6">Inicie sesión</h2>
                            <p className="mb-4 mst-4">Por favor, inicie sesión con su correo electrónico.</p>
                            <form onSubmit={handleSubmit(() => sendOtp())}>
                                <div className="form-group my-3">
                                    <label className="mandatory">Email</label>
                                    <input
                                        type="emai"
                                        placeholder="ejemplo@gmail.com"
                                        className="form-control"
                                        {...register(
                                            "email",
                                            Validators({
                                                email: true,
                                                required: true,
                                            })
                                        )}
                                    />
                                    <ErrorMessage as="aside" errors={errors} name="email" />
                                </div>

                                <div className="w-100 d-flex justify-content-center mt-5">
                                    <button className="btn btn_orange" type="submit">
                                        INICIAR SESIÓN
                                        <Icons.PersonRounded />
                                    </button>
                                </div>
                            </form>
                            <SocialLoginComponent />
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
                {openModalConfirmEmail && (
                    <ModalConfirmEmail
                        login={true}
                        handleBuy={() => {}}
                        close={() => setOpenModalConfirmEmail(false)}
                        email={watch().email}
                    />
                )}
            </Layout>
        </div>
    );
};

export default SignInPage;
