import "./BuyYourCarStyles.scss";
import React, { FC, useContext, useEffect, useState } from "react";
import Layout from "../../../layout/Layout";
import { useForm } from "react-hook-form";
import CarEntity from "../../../../../domain/entities/CarEntity";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import CarCardComponent from "../../../components/carCard/CarCardComponent";
import di from "../../../../../di/DependencyInjection";
import SearchCarsUseCase, { SearchCarsUseCaseName } from "../../../../../domain/use_cases/car/SearchCarsUseCase";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import OrderByEntity from "../../../../../domain/entities/OrderByEntity";
import FilterComponent from "./components/filterComponent/FilterComponent";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Icons from "../../../assets/Icons";
import DeleteFilterComponent from "./components/deleteComponent/DeleteFilterComponent";
import { useLocation, useParams } from "react-router-dom";
import TypeVehicleContext from "../../../../../domain/providers/typeVehicle/TypeVehicleContext";
import TypeVehicleContextType from "../../../../../domain/providers/typeVehicle/TypeVehicleContextType";
import { Helmet } from "react-helmet-async";
import BrandContext from "../../../../../domain/providers/brand/BrandContext";
import BrandContextType from "../../../../../domain/providers/brand/BrandContextType";
import ColorContext from "../../../../../domain/providers/color/ColorContext";
import ColorContextType from "../../../../../domain/providers/color/ColorContextType";
import TagContext from "../../../../../domain/providers/tag/TagContext";
import TagContextType from "../../../../../domain/providers/tag/TagContextType";
import TypeOfFuelContext from "../../../../../domain/providers/typeOfFuel/TypeOfFuelContext";
import TypeOfFuelContextType from "../../../../../domain/providers/typeOfFuel/TypeOfFuelContextType";
import SeoDropdown from "./components/seoDropdown/SeoDropdown";
import {
    contentListHybridCarSeo,
    contentListHybridCarSeo2,
    contentListUsedTrucksSeo,
    contentListUsedTrucksSeo2,
    contentListUsedCoupesSeo,
    contentListHatchbackCarSeo,
    contentListHatchbackCarSeo2,
    contentListSedanCarSeo,
    contentListSedanCarSeo2,
    contentListUsedCoupesSeo2,
} from "./constants";
import BannerYourCar from "./components/banner/BannerYourCar";
import { useNavigate } from "react-router-dom";
import { Financing } from "../../../components/financing/financing";
import { SkeletonCard } from "../../../components/skeletonCard/skeletonCard";
import OrangeFestModal from "../../../components/OrangeFestModal/OrangeFestModal";
import CarImplDto from "../../../../../data/dto/impl/CarImplDto";
import HostApi from "../../../../../data/settings/HostApi";

const orderingOptions: OrderByEntity[] = [
    {
        label: "Relevancia",
        value: {
            keyname: "relevance",
            desc: undefined,
            warranty: undefined,
        },
    },
    {
        label: "Mayor precio",
        value: {
            keyname: "price",
            desc: true,
            warranty: undefined,
        },
    },
    {
        label: "Menor precio",
        value: {
            keyname: "price",
            desc: false,
            warranty: undefined,
        },
    },
    {
        label: "Garantía",
        value: {
            keyname: "warranty",
            desc: undefined,
            warranty: true,
        },
    },
];

const CarPage: FC<{}> = () => {
    const navigate = useNavigate();
    const formFunctions = useForm();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
        watch,
        getValues,
    } = formFunctions;
    const { typeVehicles } = useContext(TypeVehicleContext) as TypeVehicleContextType;
    const { typeVehicleName } = useParams();
    const { brands } = useContext(BrandContext) as BrandContextType;
    const { colors } = useContext(ColorContext) as ColorContextType;
    const { tags } = useContext(TagContext) as TagContextType;
    const { typeOfFuels } = useContext(TypeOfFuelContext) as TypeOfFuelContextType;
    const queryParams = new URLSearchParams(window.location.search);
    const location = useLocation();
    const [showOrangeBanner, setShowOrangeBanner] = React.useState<boolean>(
        window.localStorage.getItem("orangeFestModal") ? false : true
    );

    const [cars, setCars] = useState<CarEntity[] | undefined>(undefined);
    const [totalCars, setTotalCars] = useState(0);
    const [page, setPage] = useState<number>(queryParams.get("page") ? Number(queryParams.get("page")) : 1);
    const [openFilters, setOpenFilters] = useState<boolean>(document.body.clientWidth > 968);
    const [showListOrderBy, setShowListOrderBy] = useState(false);
    const [openOrderBy, setOpenOrderBy] = useState(false);
    const [maxPages, setMaxPages] = useState<number>(1);

    const brand = brands.find((brand) => brand.name === queryParams.get("brand"));
    const model = watch("model");
    const year = queryParams.get("year") ? queryParams.get("year") : watch("year");
    const price = watch("price");
    const typeVehicleId = queryParams.get("type_vehicle") ? queryParams.get("type_vehicle") : watch("type_vehcile_id");
    let transmission = watch("type_transmission");
    if (watch("type_transmission") == 1 || watch("type_transmission") == 0) {
        transmission = watch("type_transmission") == "1" ? "Automática" : "Manual";
    }
    console.log(transmission, "transmission");
    const tagId = queryParams.get("tag") ? queryParams.get("tag") : watch("tag_id");
    const rangeMileage = watch("km");
    const fuelId = queryParams.get("typeOfFuels") ? queryParams.get("typeOfFuels") : watch("type_fuel_id");
    const colorId = queryParams.get("color") ? queryParams.get("color") : watch("color_id");
    const plateNumber = queryParams.get("plate") ? queryParams.get("plate") : watch("plate_number");
    const orderBy = watch("orderBy");

    //searcher filters
    useEffect(() => {
        setPage(queryParams.get("page") ? Number(queryParams.get("page")) : 1);
        _handleSearch();
    }, [brand, model, year, typeVehicleId, transmission, tagId, fuelId, colorId, plateNumber, orderBy]);

    useEffect(() => {
        _handleSearch();
    }, [page]);
    useEffect(() => {
        if (
            location.pathname === "/compra-tu-carro/camionetas-usadas/" ||
            location.pathname === "/compra-tu-carro/camionetas-usadas"
        ) {
            setValue("type_vehcile_id", 2);
        }
    }, []);

    const [isTimerActive, setIsTimerActive] = useState(false);
    let timer: NodeJS.Timeout | null = null; // Inicializa el temporizador
    useEffect(() => {
        if (isTimerActive) {
            timer = setTimeout(() => {
                console.log("Temporizador finalizado");
                _handleSearch(); // Llama a la función _handleSearch después del tiempo especificado
            }, 1000);
        } else if (timer !== null) {
            clearTimeout(timer); // Detener el temporizador si isTimerActive cambia a false
        }

        return () => {
            if (timer !== null) {
                clearTimeout(timer); // Limpia el temporizador si el componente se desmonta o isTimerActive cambia
            }
        };
    }, [isTimerActive]);

    const handleToggleTimer = () => {
        setIsTimerActive((prevState) => !prevState); // Iniciar o reiniciar el temporizador
    };

    useEffect(() => {
        handleToggleTimer();
    }, [price?.min, price?.max, rangeMileage?.min, rangeMileage?.max]);

    // const _handleChangeTypeVehicle = () => {
    //     setValue("type_vehcile_id", typeVehicles.find((typeVehicle) => typeVehicle.name == typeVehicleName)?.id);
    // };

    // useEffect(() => {
    //     _handleChangeTypeVehicle();
    //     console.log(typeVehicleName, "typeVehicleName");
    // }, [typeVehicleName]);

    const _handleSearch = async () => {
        try {
            setValue("year", queryParams.get("year"));
            setValue("plate_number", queryParams.get("plate"));
            // setValue("model", queryParams.get("model"));
            // setValue("price", queryParams.get("price"));
            // setValue("type_vehcile_id", queryParams.get("type_vehicle"));
            setValue("type_transmission", queryParams.get("transmission"));
            if (queryParams.get("transmission") == "1" || queryParams.get("transmission") == "0") {
                setValue("type_transmission", queryParams.get("transmission") == "1" ? "Automática" : "Manual");
                console.log("Paso");
            }
            console.log(queryParams.get("transmission"), "transmission");
            setValue("tag_id", queryParams.get("tag"));
            // setValue("km", queryParams.get("km"));
            setValue("type_fuel_id", queryParams.get("typeOfFuels"));
            setValue("color_id", queryParams.get("color"));
            setValue("plate_number", queryParams.get("plate"));
            // setValue("orderBy", queryParams.get("orderBy"));
            const brandQuery: any = brands.filter((item: any) => item.name == queryParams.get("brand"));
            setValue("brand_id", brandQuery[0]?.id ? brandQuery[0].id : "");
            const typeQuery: any = typeVehicles.filter((item: any) => item.name == queryParams.get("type_vehicle"));
            setValue("type_vehcile_id", typeQuery[0]?.id ? typeQuery[0].id : "");
            if (watch("type_vehcile_id") == 8) setValue("type_vehcile_id", 8);
            const colorQuery: any = colors.filter((item: any) => item.name == queryParams.get("color"));
            setValue("color_id", colorQuery[0]?.id ? colorQuery[0].id : "");
            const tagQuery: any = tags.filter((item: any) => item.name == queryParams.get("tag"));
            setValue("tag_id", tagQuery[0]?.id ? tagQuery[0].id : "");

            const typeFuelsQuery: any = typeOfFuels.filter((item: any) => item.name == queryParams.get("typeOfFuels"));
            setValue("type_fuel_id", typeFuelsQuery[0]?.id ? typeFuelsQuery[0].id : "");

            const data = formFunctions.getValues();
            console.log(data, "data filter");
            window.scrollTo({
                top: 0,
                behavior: "auto",
            });
            console.warn("data.search", data.search);
            setCars(undefined);
            const response = await fetch("https://api.wcar.online/api/get-cars-user/", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + HostApi.getToken(),
                },
            }).then((res) => res.json());
            console.log("🚀 ~ const_handleSearch= ~ response:", response);
            const response2 = response.map((item: any) => CarImplDto.fromJson(item.car_user));
            console.log("RESPONSE", response2);
            // @ts-ignore
            setTypingTimeout(null);
            // @ts-ignore
            setCars(response2);
            setMaxPages(response.maxPages);
            setTotalCars(response?.count);
            window.scrollTo({
                top: 0,
                behavior: "auto",
            });
        } catch (error) {
            setCars([]);
        }
    };

    const _handleOnSubmit = async (data: any) => {
        _handleSearch();
    };

    const _handleNextPage = async () => {
        const nextPage = page + 1;
        setPage(nextPage);
        queryParams.set("page", nextPage.toString());
        window.history.replaceState(null, "", "?" + queryParams.toString());
        document.querySelector(".content_app").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const _handlePreviousPage = async () => {
        const previousPage = page - 1;
        setPage(previousPage);
        queryParams.set("page", previousPage.toString());
        window.history.replaceState(null, "", "?" + queryParams.toString());
        document.querySelector(".content_app").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const _handleChangePage = async (page: number) => {
        const previousPage = page;
        setPage(previousPage);
        queryParams.set("page", previousPage.toString());
        window.history.replaceState(null, "", "?" + queryParams.toString());
        document.querySelector(".content_app").scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const _handleClearFilters = async () => {
        reset();
        setPage(1);
        navigate("/compra-tu-carro/");
    };

    const _handlePickOrderBy = (orderByValue: OrderByEntity) => {
        setOpenOrderBy(false);
        if (watch("orderBy") == orderByValue) return;
        setValue("orderBy", orderByValue);
        setPage(1);
    };

    const [typingTimeout, setTypingTimeout] = useState(null);
    const [search, setSearch] = useState("");

    const handleInputChange = (e) => {
        setValue("search", e.target.value);
        console.warn("register", e.target.value);
        setSearch(e.target.value);
        // Limpiar cualquier temporizador anterior
        clearTimeout(null);
        handleSubmit(_handleOnSubmit);

        if (e.target.value === "") {
            handleSubmit(_handleOnSubmit);
            return;
        }

        // Establecer un nuevo temporizador
        const timeout = setTimeout(() => {
            handleSubmit(_handleOnSubmit)(e);
        }, 1000); // 1 segundo

        // Guardar el temporizador en el estado
        setTypingTimeout(timeout);
    };

    return (
        <Layout>
            <Helmet>
                <title>Compra tu Carro - wcar</title>
                <meta name="description" content="Compra tu carro usado de forma fácil y segura con wcar" />
                <meta name="keywords" content="Compra tu carro" />
                <link rel="canonical" href="https://wcar.co/compra-o-vende-tu-carro-en-colombia" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <form onSubmit={handleSubmit(_handleOnSubmit)}>
                <div className="w-100 position-relative buy_your_car_page bg_gray">
                    <div className="w-100 car_search bg_search py-3">
                        <div className="container d-flex flex-column flex-md-row px-md-5 justify-content-between align-items-center">
                            <div className="input_search">
                                <img src="/assets/icons/search.svg" className="text_orange" alt="wcar" title="wcar" />
                                <input
                                    type="text"
                                    placeholder="Buscar por marca, modelo, color..."
                                    value={search}
                                    onChange={(e) => handleInputChange(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="car_list from_left_2">
                        <div className="row ">
                            <div className={`"col-md-12"} container_cars`}>
                                {((typeVehicleId === "Camioneta - SUV" && fuelId !== "Híbrido") ||
                                    location.pathname === "/compra-tu-carro/camionetas-usadas") && <BannerYourCar />}

                                {typeVehicleId === "Coupe" && fuelId !== "Híbrido" && (
                                    <BannerYourCar text="Carros Coupe Usados" />
                                )}

                                {(((fuelId === "Híbrido" || typeVehicleId === "Híbrido") &&
                                    typeVehicleId !== "Camioneta - SUV") ||
                                    location.pathname === "/compra-tu-carro/carros-hibridos-colombia") && (
                                    <BannerYourCar text="Carros Híbridos Colombia" />
                                )}

                                {((typeVehicleId === "Hatchback" && fuelId !== "Híbrido") ||
                                    location.pathname === "/compra-tu-carro/Hatchback") && (
                                    <BannerYourCar text="Hatchback Colombia" />
                                )}

                                {((typeVehicleId === "Sedan" && fuelId !== "Híbrido") ||
                                    location.pathname === "/compra-tu-carro/Sedan") && (
                                    <BannerYourCar text="Carros Sedan Usados" />
                                )}
                                <br />
                                <div className="d-block d-md-none justify-content-between">
                                    <div className="mt-1 d-flex flex-row-reverse justify-content-between">
                                        <div className="btn btn_light mt-2" onClick={() => setOpenFilters(true)}>
                                            <img src="/assets/icons/filter.svg" alt="wcar" title="wcar" />
                                            <span className="ms-2">Filtrar</span>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <DeleteFilterComponent formFunctions={formFunctions} onChange={_handleSearch} />
                                    </div>
                                </div>
                                <div className="row  w-100">
                                    {cars == undefined &&
                                        Array.from({ length: 24 }).map((_, i) => (
                                            <>
                                                <div
                                                    className={`mb-3 ${
                                                        openFilters
                                                            ? "col-md-12 col-lg-6 col-xl-4"
                                                            : "col-md-4 col-xl-3"
                                                    }`}
                                                    key={i}
                                                >
                                                    <SkeletonCard />
                                                </div>
                                                {i === (openFilters ? 5 : 7) && (
                                                    <div className="d-flex justify-content-center col-12 mb-4 mt-3">
                                                        <Financing bgColor="#00fefe" textColor="#000" />
                                                    </div>
                                                )}
                                            </>
                                        ))}
                                    {cars != undefined && cars.length == 0 && <h5>NO HAY RESULTADOS</h5>}
                                    {cars != undefined &&
                                        cars.length > 0 &&
                                        cars.map((car, index) => {
                                            return (
                                                <>
                                                    <div
                                                        className={`mb-3 ${
                                                            openFilters
                                                                ? "col-md-12 col-lg-6 col-xl-4"
                                                                : "col-md-3 col-xl-4"
                                                        }`}
                                                        key={car.id}
                                                    >
                                                        <CarCardComponent accountUser={true} car={car} />
                                                    </div>
                                                </>
                                            );
                                        })}
                                </div>
                                {maxPages > 1 && (
                                    <>
                                        <div className="w-100 d-flex justify-content-center align-items-center my-3">
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <div
                                                        className={`arrow_slider previous me-1 mt-1 ${
                                                            page <= 1 ? "disabled" : ""
                                                        }`}
                                                    >
                                                        <AiOutlineArrowLeft size={20} onClick={_handlePreviousPage} />
                                                    </div>
                                                    {/* <li className="page-item">
                                                    <a className="page-link " href="#" aria-label="Previous">
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </a>
                                                </li> */}
                                                    {Array.from({ length: maxPages }, (_, index) => (
                                                        <li key={index}>
                                                            <a
                                                                // className="page-link"
                                                                className={` page-link ${
                                                                    page === index + 1 ? "active-custom" : ""
                                                                }`}
                                                                style={{
                                                                    color: page === index + 1 ? "white" : "#ec671b",
                                                                }}
                                                                href="#"
                                                                onClick={() => _handleChangePage(index + 1)}
                                                            >
                                                                {index + 1}
                                                            </a>
                                                        </li>
                                                    ))}

                                                    <div
                                                        className={`arrow_slider next mt-1 ms-1 ${
                                                            page >= maxPages ? "disabled" : ""
                                                        }`}
                                                    >
                                                        <AiOutlineArrowRight size={20} onClick={_handleNextPage} />
                                                    </div>
                                                </ul>
                                            </nav>
                                        </div>
                                        {/* <div className="w-100 d-flex justify-content-center align-items-center my-3 ">
                                            <div className=" d-md-flex me-3 d-flex">
                                                <div
                                                    className={`arrow_slider previous me-1 ${
                                                        page <= 1 ? "disabled" : ""
                                                    }`}
                                                >
                                                    <AiOutlineArrowLeft size={20} onClick={_handlePreviousPage} />
                                                </div>
                                                <p className="d-flex align-items-center fs-6 px-2">
                                                    {page} de {maxPages}
                                                </p>
                                                <div
                                                    className={`arrow_slider next ms-1 ${
                                                        page >= maxPages ? "disabled" : ""
                                                    }`}
                                                >
                                                    <AiOutlineArrowRight size={20} onClick={_handleNextPage} />
                                                </div>
                                            </div>
                                        </div> */}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            {/* SEO camoionetas usadas */}
            {((typeVehicleId === "Camioneta - SUV" && fuelId !== "Híbrido") ||
                location.pathname === "/compra-tu-carro/camionetas-usadas") && (
                <div className="my-6 pt-4 container container-drop" style={{ marginTop: "80px", marginBottom: "40px" }}>
                    <SeoDropdown
                        options={contentListUsedTrucksSeo.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                    <SeoDropdown
                        options={contentListUsedTrucksSeo2.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                </div>
            )}

            {((typeVehicleId === "Coupe" && fuelId !== "Híbrido") ||
                location.pathname === "/compra-tu-carro/coupe") && (
                <div className="my-6 pt-4 container container-drop" style={{ marginTop: "80px", marginBottom: "40px" }}>
                    <div className="w-100 w-md-50">
                        <SeoDropdown
                            options={contentListUsedCoupesSeo.map((content) => {
                                return {
                                    title: content.title,
                                    content: content.content,
                                    typeElement: content.type,
                                    image: content.imageUrl,
                                    alt: content.alt,
                                };
                            })}
                        />
                    </div>
                    <div className="w-100 w-md-50">
                        <SeoDropdown
                            options={contentListUsedCoupesSeo2.map((content) => {
                                return {
                                    title: content.title,
                                    content: content.content,
                                    typeElement: content.type,
                                    image: content.imageUrl,
                                    alt: content.alt,
                                };
                            })}
                        />
                    </div>
                </div>
            )}
            {/* SEO Sedan */}
            {typeVehicleId === "Sedan" && fuelId !== "Híbrido" && (
                <div className="my-6 pt-4 container container-drop" style={{ marginTop: "80px", marginBottom: "40px" }}>
                    <SeoDropdown
                        options={contentListSedanCarSeo.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                    <SeoDropdown
                        options={contentListSedanCarSeo2.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                </div>
            )}
            {/* SEO - Combustible: Hibrido */}
            {(fuelId === "Híbrido" || typeVehicleId === "Híbrido") && typeVehicleId !== "Camioneta - SUV" && (
                <div
                    className="my-6 pt-4 container container-drop"
                    style={{
                        marginTop: "80px",
                        marginBottom: "40px",
                    }}
                >
                    <SeoDropdown
                        options={contentListHybridCarSeo.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                    <SeoDropdown
                        options={contentListHybridCarSeo2.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                </div>
            )}
            {/* SEO Hatchback */}
            {((typeVehicleId === "Hatchback" && fuelId !== "Híbrido") ||
                location.pathname === "/compra-tu-carro/Hatchback") && (
                <div className="my-6 pt-4 container container-drop" style={{ marginTop: "80px", marginBottom: "40px" }}>
                    <SeoDropdown
                        options={contentListHatchbackCarSeo.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                    <SeoDropdown
                        options={contentListHatchbackCarSeo2.map((content) => {
                            return {
                                title: content.title,
                                content: content.content,
                                typeElement: content.type,
                                image: content.imageUrl,
                                alt: content.alt,
                            };
                        })}
                    />
                </div>
            )}
            {/* {showOrangeBanner && <OrangeFestModal setIsOpen={setShowOrangeBanner} />} */}
        </Layout>
    );
};

export default CarPage;
