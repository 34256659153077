import { ErrorMessage } from "@hookform/error-message";
import Icons from "../../../../../assets/Icons";
import "./ModalConfirmEmailStyles.scss";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../../../components/Button";
import ModalsContext from "../../../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../../../domain/providers/modal/ModalsContextType";
import LoadingComponent from "../../../../../components/LoadingComponent/LoadingComponent";
import di from "../../../../../../../di/DependencyInjection";
import SignInUseCase, { SignInUseCaseName } from "../../../../../../../domain/use_cases/auth/SignInUseCase";
import { isRight } from "fp-ts/lib/Either";
import { routes } from "../../../../../routes/RoutesComponent";

export default function ModalConfirmEmail({
    email,
    close,
    otpCode,
    handleBuy,
    login = false,
}: {
    email: string;
    login?: boolean;
    close: () => void;
    handleBuy: () => void;
    otpCode?: string;
}) {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const inputsRef = useRef([]);
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const [loading, setLoading] = useState(false);
    const handleChange = (value, index) => {
        if (!/^\d*$/.test(value)) return; // Only allow numeric input
        const newOtp = [...otp];
        newOtp[index] = value.slice(-1); // Only take the last digit
        setOtp(newOtp);

        // Automatically focus the next input
        if (value && index < 5) {
            inputsRef.current[index + 1].focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
            // Move focus to the previous input on backspace
            inputsRef.current[index - 1].focus();
        }
    };

    const handlePaste = (e) => {
        e.preventDefault();
        const pasteData = e.clipboardData.getData("text").slice(0, 6);
        if (/^\d{1,6}$/.test(pasteData)) {
            const newOtp = pasteData.split("");
            setOtp(newOtp.concat(new Array(6 - newOtp.length).fill("")));
            inputsRef.current[Math.min(pasteData.length - 1, 5)].focus();
        }
    };

    const verifyOtp = () => {
        let url = "https://api.wcar.online/api/verificate-otp-code/";
        if (login) {
            url = "https://api.wcar.online/api/verificate-otp-code-login/";
        }
        if (!login) {
            console.log(otp.join(""), otpCode);
            if (otp.join("") == otpCode) {
                close();
                handleBuy();
            } else {
                console.error("OTP verification failed");
                addToast("Código incorrecto", "error", undefined);
            }
            return;
        }
        setLoading(true);

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email,
                code: otp.join(""),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log("Success:", data);
                if (data.success) {
                    handleBuy();
                } else {
                    console.error("OTP verification failed");
                    addToast("Código incorrecto", "error", undefined);
                    setLoading(false);
                }
            })
            .catch((error) => {
                addToast("Código incorrecto", "error", undefined);
                console.error("Error:", error);
                setLoading(false);
            });

        console.log(otp.join(""));
    };
    const navigate = useNavigate();

    const _handleSubmit = async () => {
        const response = await di.get<SignInUseCase>(SignInUseCaseName).call(email, otp.join(""));
        if (isRight(response)) {
            navigate(routes.home.relativePath);
        } else {
            addToast(response.left.message ?? "Credenciales erradas", "error", undefined);
        }
    };

    return (
        <div className="box_modal_amount">
            <div className="closer">
                <div className="modal-amount-buy position-relative">
                    <div className="icon-close" onClick={close} style={{ zIndex: 3 }}>
                        <Icons.Clear />
                    </div>
                    {loading && (
                        <div className="loading_container" style={{ backgroundColor: "#fff7", zIndex: 2 }}>
                            <LoadingComponent />
                        </div>
                    )}
                    <h4 className="modal-title text-black">Confirma tu correo</h4>
                    <p className="text-center px-5 fw-bold fs-6 mt-3">Se ha enviado un código a tu correo: {email}</p>
                    <div className="form-group mt-2 input-box">
                        <div className="d-flex justify-content-center align-items-center flex-wrap gap-4 my-4">
                            <div style={{ display: "flex", gap: "8px" }}>
                                {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        value={digit}
                                        onChange={(e) => handleChange(e.target.value, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        onPaste={handlePaste}
                                        ref={(el) => (inputsRef.current[index] = el)}
                                        maxLength={1}
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            fontSize: "20px",
                                            textAlign: "center",
                                            border: "1px solid #ddd",
                                            borderRadius: "4px",
                                            outline: "none",
                                        }}
                                    />
                                ))}
                            </div>
                            <p style={{ color: "#888", fontSize: "14px", marginTop: "0px", marginLeft: "8px" }}>
                                Por favor, ingrese el código de 6 dígitos.
                            </p>
                        </div>
                        <div className="modal-buttons" style={{ zIndex: -2 }}>
                            <Button
                                onClick={() => (login ? _handleSubmit() : verifyOtp())}
                                label="Verificar"
                                className={`${otp.join("").length != 6 ? "bg-color" : ""}`}
                                disabled={otp.join("").length != 6 ? true : false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
