import "./newInsurancePageStyles.scss";

import Layout from "../../../layout/Layout";
import { FC, useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";
import ModalGarantie from "../../car/detailedCar/component/ModalGarantie";
import AccordeonComponent from "../../../components/accordeon/AccordeonComponent";
import Button from "../../../components/Button";
import { CardInsurance } from "../insurances/card/card";

const NewInsurancePage: FC<{}> = () => {
    const [showGaratieModal, setShowGarantieModal] = useState(false);

    const [srvCode, setSrvCode] = useState("");

    const cardInfo = {
        side_top: true,
        flex: true,
        titleBlack: `
        En wcar, ofrecemos para ti los más acertados
        seguros de vehículos en Colombia.
        Nuestra filosofía de transparencia brutal
        garantiza que encuentres el seguro perfecto
        para tu carro, sin sorpresas y con la confianza
        que solo wcar puede ofrecer.`,
        imgUrl: "./assets/services/girl-wcar.jpg",
        directionReverse: true,
        // cardColor: "#ec671b",
        cardColor: "#1e1e1e",
        textColor: "#fff",
        cardWidth: "100%",
    };

    const cardInfo2 = {
        flex: true,
        titleBlack:
            "Te recomendamos navegar nuestra web donde encontrarás más información sobre todas las soluciones que ofrecemos en wcar.",
        imgUrl: "./assets/services/rim-modern-car.jpg",

        side_top: true,
        directionReverse: true,
        cardColor: "#ec671b",
        textColor: "#fff",
        cardWidth: "100%",
    };

    useEffect(() => {
        const scriptContent = `
       !function(a,m,o,c,r,m){a[o+c]=a[o+c]||{setMeta:function(p){this.params=(this.params||[]).concat([p])}},a[o+r]=a[o+r]||function(f){a[o+r].f=(a[o+r].f||[]).concat([f])},a[o+r]({id:"1421215",hash:"62d8146dcbec3ec6c452470d162a810c",locale:"es"}),a[o+m]=a[o+m]||function(f,k){a[o+m].f=(a[o+m].f||[]).concat([[f,k]])}}(window,0,"amo_forms_","params","load","loaded");
        `;

        const script = document.createElement("script");
        script.async = true;
        script.innerHTML = scriptContent;
        document.body.appendChild(script);

        const kommoScript = document.createElement("script");
        kommoScript.id = "amoforms_script_1421215";
        kommoScript.async = true;
        kommoScript.src = "https://forms.kommo.com/forms/assets/js/amoforms.js?1734474926";

        setTimeout(() => {
            document.getElementById("form-seguro")?.appendChild(kommoScript);
        }, 1000); // Espera 1 segundo para asegurarse de que todo el DOM esté cargado

        return () => {
            document.body.removeChild(script);
        };
    }, []);
    useEffect(() => {
        // Añade el hash #list a la URL si no existe
        if (!window.location.hash) {
            window.location.hash = "#form-seguro";
        }

        // Desplázate al elemento con el ID del hash
        const targetElement = document.getElementById("form-seguro");
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    return (
        <div className="insurance_page">
            <Helmet>
                <title>#1 en Seguros de Vehículos en Colombia </title>
                <meta
                    name="description"
                    content="Conoce todo lo que necesitas saber sobre seguros de vehículos en Colombia, en wcar más que vender te asesoramos."
                />
                <meta
                    name="keywords"
                    content="Seguros de vehículos, Qué es un seguro todo riesgo en Colombia, Necesitas seguro del automóvil, seguro de automóvil"
                />
                <link rel="canonical" href="https://wcar.co/" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Layout>
                <section className="section_1">
                    <img
                        src="/assets/services/bg_insurance2.png"
                        alt="wcar"
                        title="wcar"
                        className="img-fluid w-100 bg_img d-block d-md-none"
                    />
                    <div className="content position-md-absolute mt-4 mt-md-0 container-form">
                        <div className="container">
                            <div className="col-md-5 ms-5">
                                <div className="d-none d-md-block side side_top side_blue_neon mb-3" />
                                <h2 className="text_md_white text_bold text-center text-md-start">
                                    N.º 1 en Seguros de Vehículos
                                    <br className="d-none d-md-block" />
                                    <span className="text_md_white text_light text_italic text_orange">
                                        en Colombia | wcar
                                    </span>
                                </h2>
                            </div>
                            <h3 className="text_md_white text_italic ms-5 mt-2" style={{ color: "#00fefe" }}>
                                Llena el formulario y recibe cotización <br /> en un abrir y cerrar ojos
                            </h3>
                            <br />
                            <div className="d-flex justify-content-center d-md-block ms-5">
                                {/* <Button label="COMPRA TU SEGURO" onClick={() => setShowGarantieModal(true)} /> */}
                            </div>
                        </div>
                        <div
                            id="form-seguro"
                            className=" container"
                            style={{ textAlign: "center", paddingTop: 40 }}
                        ></div>
                    </div>
                    <img
                        src="/assets/services/bg_insurance2.png"
                        className="first_img img-fluid w-100 d-none d-md-block bg_img bg_1"
                        alt="Financiación con wcar"
                        title="Financiación con wcar"
                    />
                </section>

                <section className="section_2 py-5 mt-2">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-md-8">
                                <div>
                                    <div className="side side_top">
                                        <h2 className="text_bold ">
                                            <span className="text_italic text_orange">
                                                N.º 1 en Seguros de Vehículos{" "}
                                            </span>
                                            en Colombia | wcar
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-3"></div>
                            <div className="col-md-6">
                                <div>
                                    <div className="">
                                        <h3 className="text_bold text-center">
                                            Seguros de Vehículos
                                            <span className="text_italic text_orange"> en Colombia</span>
                                        </h3>
                                        <p className={"text-black fs-6  text-center"}>
                                            Descubre los mejores seguros de vehículos en Colombia con{" "}
                                            <strong>wcar</strong>. Transparencia brutal, confiabilidad y los mejores
                                            aliados para tu carro.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3"></div>
                        </div>
                    </div>
                </section>
                <section className="workshop_and_service_drop_lists pb-3 pb-md-5">
                    <br />
                    <br />
                    <div className="drop_lists text-black container" id={"list"}>
                        <AccordeonComponent
                            title={
                                <>
                                    <h3>
                                        Seguro <span>wcar</span>
                                    </h3>
                                </>
                            }
                            options={[
                                {
                                    title: (
                                        <p className="subtitle_drop_list">
                                            ¿Necesitas Seguros de Vehículos en Colombia?
                                        </p>
                                    ),
                                    content: (
                                        <p className="item_drop_list">
                                            Elegir el seguro adecuado para tu vehículo puede ser una tarea compleja. En{" "}
                                            <strong>wcar</strong>, simplificamos el proceso para ti. Te ayudamos a
                                            encontrar el seguro que mejor se adapta a tus necesidades y presupuesto, con
                                            la seguridad y confianza que mereces.
                                        </p>
                                    ),
                                },
                                {
                                    title: (
                                        <p className="subtitle_drop_list">Seguro de Carro con Transparencia Brutal</p>
                                    ),
                                    content: (
                                        <p className="item_drop_list">
                                            En <strong>wcar</strong>, nos enorgullece nuestra transparencia brutal. Esto
                                            significa que te mostramos todas las opciones disponibles, explicamos
                                            claramente cada póliza y te asesoramos para que tomes la mejor decisión.
                                        </p>
                                    ),
                                },
                                {
                                    title: (
                                        <p className="subtitle_drop_list">Seguro para Carro Confiable y Transparente</p>
                                    ),
                                    content: (
                                        <p className="item_drop_list">
                                            Confía en <strong>wcar</strong> para encontrar un seguro de carro confiable
                                            y transparente. Trabajamos con los mejores aliados del sector para ofrecerte
                                            opciones que se adaptan a ti y a tu vehículo.
                                        </p>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </section>
                {/* <Card cardInfo={cardInfo2} setShowGarantieModal={setShowGarantieModal} setSrvCode={setSrvCode} /> */}
                <CardInsurance cardInfo={cardInfo} />

                <section className="workshop_and_service_drop_lists pb-3 pb-md-5">
                    <br />
                    <br />
                    <div className="drop_lists text-black container">
                        <AccordeonComponent
                            title={
                                <>
                                    <h3>
                                        Preguntas <span>frecuentes</span>
                                    </h3>
                                    <p className="subtitle_drop_list mb-md-3">Sobre Seguros de Vehículos en Colombia</p>
                                </>
                            }
                            options={[
                                {
                                    title: (
                                        <p className="subtitle_drop_list">
                                            ¿Qué cubre un seguro de todo riesgo para vehículos?
                                        </p>
                                    ),
                                    content: (
                                        <p className="item_drop_list">
                                            N.º 1 en Seguros de Vehículos Un seguro de todo riesgo cubre daños propios y
                                            a terceros, incluyendo robo, accidentes y desastres naturales. Es la opción
                                            más completa para proteger tu vehículo.
                                        </p>
                                    ),
                                },
                                {
                                    title: (
                                        <p className="subtitle_drop_list">
                                            ¿Cómo puedo obtener un seguro de vehículo en wcar?
                                        </p>
                                    ),
                                    content: (
                                        <p className="item_drop_list">
                                            En <strong>wcar</strong>, simplemente contáctanos, te asesoraremos en cada
                                            paso del proceso para encontrar el seguro que mejor se adapte a tus
                                            necesidades
                                        </p>
                                    ),
                                },
                                {
                                    title: (
                                        <p className="subtitle_drop_list">
                                            ¿Qué debo considerar al elegir un seguro de vehículo?
                                        </p>
                                    ),
                                    content: (
                                        <p className="item_drop_list">
                                            Debes considerar la cobertura, el costo, la reputación de la aseguradora y
                                            tus necesidades específicas. En <strong>wcar</strong>, te ayudamos a evaluar
                                            todas estas variables para tomar la mejor decisión
                                        </p>
                                    ),
                                },
                                {
                                    title: (
                                        <p className="subtitle_drop_list">
                                            ¿wcar ofrece seguros de vehículos en toda Colombia?
                                        </p>
                                    ),
                                    content: (
                                        <p className="item_drop_list">
                                            Sí, ofrecemos seguros de vehículos en todo el territorio colombiano. Nuestro
                                            objetivo es brindarte la mejor cobertura sin importar dónde te encuentres.
                                        </p>
                                    ),
                                },
                            ]}
                        />
                    </div>
                </section>
                <div>
                    <CardInsurance cardInfo={cardInfo2} />
                </div>
                {/* {cardsInfo.map((cardInfo, i) => {
                    return (
                        <Card
                            key={i}
                            cardInfo={cardInfo}
                            setShowGarantieModal={setShowGarantieModal}
                            setSrvCode={setSrvCode}
                        />
                    );
                })} */}
                {/* {showOrangeBanner && <OrangeFestModal setIsOpen={setShowOrangeBanner} />} */}
            </Layout>
            {showGaratieModal && (
                <ModalGarantie
                    id={"185"}
                    close={() => setShowGarantieModal(false)}
                    carValue={100000}
                    SrvCode={"1002"}
                />
            )}
        </div>
    );
};

export default NewInsurancePage;
